import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { BasePopover, BasePopoverTrigger, BasePopoverContent } from '@/shared/ui/popover';
import { BaseLink } from '@/shared/ui/link';
import Constants from '../../util/constants';
import * as actionCreators from '@/store/actions';

import styles from './LangPicker.module.css';

const LangPicker = () => {
  const { i18n, t } = useTranslation(['general']);

  const [opened, setOpened] = useState(false);

  const isLoggedIn = useSelector((state) => state.authorization.isLoggedIn);

  const dispatch = useDispatch();

  function onChooseLanguage({ code }) {
    dispatch(actionCreators.clearErrors());
    dispatch(actionCreators.langChange(code, isLoggedIn, i18n));

    setOpened(false);
  }

  return (
    <BasePopover
      placement="top-start"
      open={opened}
      onOpenChange={setOpened}
    >
      <BasePopoverTrigger asChild={true} onClick={() => setOpened((val) => !val)}>
        <BaseLink href="#" testId="footer-lang-picker">{t('general:language')}</BaseLink>
      </BasePopoverTrigger>
      <BasePopoverContent>
        <ul>
          {Constants.supportedLanguages.map((item) => (
            <li
              className={cn(styles.listItem, { [styles.listItemActive]: item.code === i18n.language })}
              key={item.code}
              onClick={() => onChooseLanguage(item)}
              data-testid={`footer-lang-picker-${item.code}`}
            >
              {t(`general:langNames.${item.code}`)}
            </li>
          ))}
        </ul>
      </BasePopoverContent>
    </BasePopover>
  );
};

export default LangPicker;
