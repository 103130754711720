import React from 'react';
import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';

import * as actionCreators from '../store/actions';
import { selectToasts } from '../store/reducers/view';
import { TOASTS_ICONS } from '@/shared/constants/toasts';
import { BaseSpinner } from '@/shared/ui/loader/index';

import { ReactComponent as WarningIcon } from '@/resources/assets/icons/warning.svg';

import styles from './Toaster.module.css';

const Toaster = () => {
  const toasts = useSelector(selectToasts);
  const dispatch = useDispatch();

  const breakpoint = useSelector((state) => state.view.breakpoint);

  const showIcon = (type) => {
    switch (type) {
      case TOASTS_ICONS.warning:
        return <WarningIcon />;
      case TOASTS_ICONS.loader:
        return <BaseSpinner size='small' position='top' />;
      default:
        return null;
    }
  };

  return (
    <CToaster
      style={{
        width: '320px',
        paddingTop: '12px',
        paddingRight: breakpoint !== 'small' ? '20px' : '12px',
        top: 0,
        right: 0,
      }}
    >
      {toasts.map((toast, idx) => (
        <React.Fragment key={toast.id}>
          <CToast
            id={styles.root}
            visible
            autohide={!toast.hasCloseButton}
            onClick={() =>
              !toast.hasCloseButton && dispatch(actionCreators.removeToastById(toast.id))
            }
          >
            <CToastHeader className={styles.toastHeader}>
              {toast.header}
              {toast.hasCloseButton && (
                <div
                  className={styles.customCloseButton}
                  onClick={() => dispatch(actionCreators.removeToastById(toast.id))}
                >
                  &times;
                </div>
              )}
            </CToastHeader>
            <CToastBody className={styles.toastBody}>
              {showIcon(toast.icon)}
              <div>{toast.message}</div>
            </CToastBody>
          </CToast>
        </React.Fragment>
      ))}
    </CToaster>
  );
};

export default Toaster;
