import { Fragment } from 'react';

export const UNITS_SYSTEMS = [
  { id: 'M', name: 'Metric' },
  { id: 'I', name: 'Imperial' },
];

export const UNITS = {
  length: {
    M: 'm',
    I: 'ft',
  },
  square: {
    M: (
      <Fragment>
        m<sup className='sup'>2</sup>
      </Fragment>
    ),
    I: (
      <Fragment>
        ft<sup className='sup'>2</sup>
      </Fragment>
    ),
  },
};
