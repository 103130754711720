import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseLink } from '@/shared/ui/link';
import { LINKS } from '@/shared/constants';
import LangPicker from '@/app/ui/LangPicker';

import styles from './TheFooter.module.css';


const { REACT_APP_VER } = process.env;

const TheFooter = () => {
  const { t } = useTranslation(['footer']);

  return (
    <footer className={styles.root}>
      <ul className={styles.rightMenu}>
        <li className={styles.rightMenuItem}>
          <span>&copy; {`${new Date().getFullYear()} Atmotech Inc. v.${REACT_APP_VER}`}</span>
        </li>
        <li className={styles.rightMenuItem}>
          <BaseLink href={LINKS.privacyPolicy} target="_blank" testId='footer-privacy-policy'>
            {t('footer:privacyPolicy')}
          </BaseLink>
        </li>
        <li className={styles.rightMenuItem}>
          <BaseLink href={LINKS.termsOfService} target="_blank" testId='footer-terms'>
            {t('footer:terms')}
          </BaseLink>
        </li>
        <li className={styles.rightMenuItem}>
          <BaseLink href={LINKS.support} target="_blank" testId='footer-support'>
            {t('footer:support')}
          </BaseLink>
        </li>
        <li className={styles.rightMenuItem}>
          <BaseLink href={LINKS.serviceStatusLink} target="_blank" testId='footer-service'>
            {t('footer:service')}
          </BaseLink>
        </li>
        <li className={styles.rightMenuItem}>
          <LangPicker />
        </li>
      </ul>
    </footer>
  );
};

export default TheFooter;
