import cn from 'classnames';

import styles from './BasePopover.module.css';

const BasePopoverItem = ({ children, className, href, isActive, onClick, ...rest }) => {
  const classnames = [
    styles.item,
    isActive && styles.itemActive,
    className,
  ];

  function clickHandler(event) {
    if (onClick) {
      onClick();
    }

    if (!href) {
      event.preventDefault();
    }
  }

  return (
    <a className={cn(classnames)} href={href} {...rest} onClick={clickHandler}>
      {children}
    </a>
  );
}

export default BasePopoverItem;
