export const LINKS = {
  appLink: 'https://atmotube.com',
  appstoreLink: 'https://apps.apple.com/us/app/atmocube-indoor-air-quality/id1564160649',
  appstoreLinkForBtn: 'https://apps.apple.com/us/app/atmocube-business/id1582552605',
  atmocubePages: 'https://atmotube.com/pages/atmocube_app',
  cookiesLearnMoreLink: 'https://atmotube.com/privacy/',
  deviceIDHelpLink: 'https://atmotube.com/atmocube-support/where-is-the-device-id-located/',
  displayApp: 'https://atmotube.com/atmocube-app',
  ethernetConnectionGuideLink: 'https://atmotube.com/atmocube-support/ethernet-connection-guide',
  faqLink: 'https://atmotube.com/atmocube-support',
  firmwareLink: 'https://atmotube.com/atmocube-support/atmocube-firmware-versions-and-release-notes',
  googleplayLink: 'https://play.google.com/store/apps/details?id=com.atmotech.atmocube.app',
  googleplayLinkForBtn:'https://play.google.com/store/apps/details?id=com.atmotech.atmocube.admin&hl=en&gl=US',
  planUpgrade: 'https://atmotube.com/atmocube_plan_upgrade',
  privacyPolicy: 'https://atmotube.com/privacy/',
  quickSetupGuide: 'https://atmotube.com/atmocube-support/quick-setup-guide',
  regulatoryLink: 'https://atmotube.com/regulatory',
  releaseNotes: 'https://atmotube.com/release-notes',
  serviceStatusLink: 'https://status.atmocube.app/',
  startLink: 'https://atmocube.app/',
  support: 'https://atmotube.com/atmocube-support/',
  termsLink: 'https://atmotube.com/terms-and-conditions',
  termsOfService: 'https://atmotube.com/terms-and-conditions/',
  usingDisplayModeLink: 'https://atmotube.com/atmocube-support/using-atmocube-display-mode/',
  versionsInfo: 'https://atmotube.com/atmocube-support/atmocube-firmware-versions-and-release-notes',
  warrantyLink: 'https://atmotube.com/warranty',
}
