import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthClass } from '@/shared/helpers';
import axios from '../../util/axios-config';

const initialState = {
  allCommands: [],
  fetchCommandsError: {},
  currentCommand: {},
  latestCommands: {},
};

export const fetchAllCommands = createAsyncThunk(
  'commands/fetchAllCommands',
  async (currentOrganizationId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`organizations/${currentOrganizationId}/commands/`, {
        headers: AuthClass.authHeaders(),
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const fetchCommandById = createAsyncThunk(
  'commands/fetchCommandById',
  async ({ currentOrganizationId, commandId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `organizations/${currentOrganizationId}/commands/${commandId}/`,
        {
          headers: AuthClass.authHeaders(),
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const commandsSlice = createSlice({
  name: 'commands',
  initialState,
  reducers: {
    storeLatestCommands: (state, action) => {
      state.latestCommands = action.payload;
    },
  },
  extraReducers: {
    [fetchAllCommands.fulfilled]: (state, action) => {
      state.allCommands = action.payload;
    },
    [fetchAllCommands.rejected]: (state, action) => {
      state.fetchCommandsError = action.payload;
      state.allCommands = [];
    },
  },
});

export const { getDeviceCommand, storeLatestCommands } = commandsSlice.actions;
export default commandsSlice.reducer;
