import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import { BaseButton } from '@/shared/ui/button';
import { ActionButton } from '@/shared/ui/action-button';

import styles from './BaseModal.module.css';

const BaseModal = ({
  className,
  visible = false,
  children,
  titleText,
  confirmText,
  cancelText,
  size = 'md',
  loading,
  disabled,
  onClose,
  onSubmit,
  showCancel = true,
}) => {
  const classnames = [
    styles.root,
    visible && styles.rootVisible,
    size && styles[`rootSize--${size}`],
    className,
  ];

  const { t } = useTranslation(['general']);

  const overlayRef = useRef(null);
  const containerRef = useRef(null);

  const [animationIn, setAnimationIn] = useState(false);

  useEffect(() => {
    if (visible) {
      setAnimationIn(true);
    }
  }, [visible]);

  function handleClose() {
    setAnimationIn(false);

    setTimeout(() => {
      onClose();
    }, 300);
  }

  return (
    <div className={cn(classnames)}>
      <CSSTransition in={animationIn} nodeRef={overlayRef} timeout={300} classNames="overlay">
        <div ref={overlayRef} className={styles.overlay} onClick={handleClose} data-testid='modal-close-back' />
      </CSSTransition>
      <div className={styles.centerer}>
        <CSSTransition in={animationIn} nodeRef={containerRef} timeout={300} classNames="modal">
          <div ref={containerRef} className={styles.container}>
            <header className={styles.header}>
              <strong className={styles.title}>{titleText}</strong>
              <button className={styles.closeBtn} onClick={handleClose} type="button" data-testid='modal-close-cross'>
                ×
              </button>
            </header>
            <div className={styles.body}>{children}</div>
            <footer className={styles.footer}>
              {showCancel && (
                <BaseButton
                  color="cancel"
                  loading={loading && loading.cancel}
                  disabled={disabled && disabled.cancel}
                  onClick={handleClose}
                  testId="modal-cancel"
                >
                  {cancelText || t('general:cancel')}
                </BaseButton>
              )}
              <ActionButton
                loading={loading && loading.submit}
                disabled={disabled && disabled.submit}
                onClick={onSubmit}
                testId="modal-submit"
              >
                {confirmText || t('general:submit')}
              </ActionButton>
            </footer>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default BaseModal;
