import * as actionTypes from '../actions/actionTypes';
import { CurrentOrgId } from '../../util/preferences';

const initialState = {
  email: '',
  first_name: '',
  new_email: '',
  alert_email_device_offline: false,
  alert_email_device_online: false,
  alert_email_parameter_change: false,
  alert_email_low_battery: false,
  last_name: '',
  organizations: [],
  currentOrganizationId: CurrentOrgId.get() != null ? CurrentOrgId.get() : null,
  locations: [],
  units: 'I',
  primaryFetch: false,
  ownId: null,
  hasDevices: true,
  api_keys: {},
  notifications_minimum_interval: null,
  nonAuthorizedLang: null,
};

function getDefaultOrgId(orgArr, currState) {
  const { currentOrganizationId } = currState;

  if (orgArr && orgArr.length > 0) {
    if (currentOrganizationId != null && orgArr.find((org) => org.id === currentOrganizationId)) {
      return currentOrganizationId;
    }

    return orgArr[0].id;
  }

  return null;
}

const reducer = (state = initialState, action) => {
  const { type } = action;

  if (type === actionTypes.currentUser.GET_CURRENT_USER_INFO_REQUEST) {
    return { ...state, primaryFetch: true };
  }

  switch (type) {
    case actionTypes.currentUser.SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case actionTypes.authorization.LOG_OUT:
      return initialState;
    case actionTypes.currentUser.GET_CURRENT_USER_INFO_SUCCESS:
      const {
        organizations,
        locations,
        units,
        email,
        first_name,
        last_name,
        new_email,
        alert_email_device_offline,
        alert_email_device_online,
        alert_email_parameter_change,
        alert_email_low_battery,
        id,
        api_keys,
        notifications_minimum_interval,
        is_helpdesk_admin,
        is_demo,
      } = action;
      const defOrg = getDefaultOrgId(organizations, state);
      CurrentOrgId.set(defOrg);
      return {
        ...state,
        organizations,
        locations,
        units,
        email,
        first_name,
        last_name,
        new_email,
        alert_email_device_offline,
        alert_email_device_online,
        alert_email_parameter_change,
        alert_email_low_battery,
        currentOrganizationId: defOrg,
        primaryFetch: false,
        ownId: id,
        api_keys,
        notifications_minimum_interval,
        is_helpdesk_admin,
        is_demo,
      };
    case actionTypes.currentUser.CREATE_ORG_SUCCESS:
      if (state.currentOrganizationId == null || state.organizations.length === 0) {
        CurrentOrgId.set(action.organization.id);
      }
      return {
        ...state,
        fetching: false,
        organizations: [
          ...state.organizations,
          { ...action.organization, role: 'admin', owner: state.ownId },
        ],
        currentOrganizationId: action.organization.id,
      };
    case actionTypes.currentUser.SET_CURRENT_ORGANIZATION_ID:
      CurrentOrgId.set(action.id);
      return { ...state, currentOrganizationId: action.id };
    case actionTypes.currentUser.CREATE_LOC_SUCCESS:
      return { ...state, locations: [...state.locations, { ...action.location, role: 'admin' }] };
    case actionTypes.currentUser.EDIT_ORG_SUCCESS:
      const newOrganizations = state.organizations.slice();
      const ind1 = newOrganizations.findIndex((org) => org.id === action.organization.id);
      for (let key in action.organization) {
        newOrganizations[ind1][key] = action.organization[key];
      }
      return { ...state, organizations: newOrganizations };
    case actionTypes.currentUser.EDIT_LOC_SUCCESS:
      const newLocations = state.locations.slice();
      const ind2 = newLocations.findIndex((loc) => loc.id === action.location.id);
      for (let key in action.location) {
        newLocations[ind2][key] = action.location[key];
      }
      return { ...state, locations: newLocations };
    case actionTypes.currentUser.DELETE_LOC_SUCCESS:
      return { ...state, locations: state.locations.filter((loc) => loc.id !== action.locationId) };
    case actionTypes.currentUser.EDIT_USER_SUCCESS:
      return { ...state, ...action.newUserData };
    case actionTypes.currentUser.UPDATE_ORGANIZATION:
      const allOrganizations = state.organizations.slice();
      const currentOrganizationIndex = allOrganizations.findIndex(
        (org) => org.id === action.organization.id
      );
      for (let key in action.organization) {
        allOrganizations[currentOrganizationIndex][key] = action.organization[key];
      }
      return { ...state, organizations: allOrganizations };
    case actionTypes.currentUser.UPDATE_CURRENT_USER_INFO:
      const {
        organization: updatedOrganization,
        locations: newLocArr,
        first_name: firstName,
        last_name: lastName,
        currentOrgId,
      } = action;
      const allOrganizationsArr = state.organizations.slice();
      const currentOrgIndex = allOrganizationsArr.findIndex(
        (org) => org.id === updatedOrganization.id
      );
      for (let key in updatedOrganization) {
        allOrganizationsArr[currentOrgIndex][key] = updatedOrganization[key];
      }
      const newState = {
        ...state,
        organizations: allOrganizationsArr,
        first_name: firstName,
        last_name: lastName,
      };
      newState.locations = [
        ...state.locations.filter((loc) => loc.organization !== currentOrgId),
        ...newLocArr,
      ];
      return newState;
    case actionTypes.currentUser.SET_HAS_DEVICES:
      const { hasDevices } = action;
      return { ...state, hasDevices: hasDevices };
    case actionTypes.currentUser.SET_NEW_API_KEY:
      const currentId = CurrentOrgId.get();
      const newAPIKeys = { ...state.api_keys, [currentId]: action.api_key };
      return { ...state, api_keys: newAPIKeys };
    case actionTypes.currentUser.NON_AUTHORIZED_LANG_CHANGE:
      return { ...state, nonAuthorizedLang: action.lang };
    default:
      return state;
  }
};

export default reducer;

export const selectCurrentEmail = (state) => state.currentUser.email;
export const selectRequestedNewEmail = (state) => state.currentUser.new_email;
export const selectCurrentFName = (state) => state.currentUser.first_name;
export const selectCurrentLName = (state) => state.currentUser.last_name;
export const selectCurrentOfflineNoti = (state) => state.currentUser.alert_email_device_offline;
export const selectCurrentOnlineNoti = (state) => state.currentUser.alert_email_device_online;
export const selectCurrentEmailChangeNoti = (state) =>
  state.currentUser.alert_email_parameter_change;
export const selectCurrentLowBatteryNotification = (state) =>
  state.currentUser.alert_email_low_battery;
export const selectCurrentMinInterval = (state) => state.currentUser.notifications_minimum_interval;
export const selectUserUnits = (state) => state.currentUser.units;
// @deprecated Use selectCurrentOrganizationId from src/store/selectors/current-user.js
export const selectCurrentOrganizationId = (state) => state.currentUser.currentOrganizationId;
export const selectCurrentOrganization = (state) =>
  state.currentUser.organizations.find(
    (organization) => organization.id === state.currentUser.currentOrganizationId
  );
export const selectOrganizations = (state) => state.currentUser.organizations;
export const selectCurrentUserId = (state) => state.currentUser.ownId;
export const selectCurrentUserIsHelpDeskAdmin = (state) => state.currentUser.is_helpdesk_admin;
export const selectCurrentUserIsDemo = (state) => state.currentUser.is_demo;
