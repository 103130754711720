import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

import { showToast, changeToast, deleteToast } from '@/shared/helpers';
import wsUrl from '@/shared/helpers/ws-config';
import { selectCurrentOrganizationId } from '@/store/reducers/currentUser';
import { storeLatestCommands } from '@/store/reducers/commands';
import { AuthClass } from '@/shared/helpers';
import { COMMANDS_STATUS, COMMANDS_TYPES, TOASTS_ICONS } from '@/shared/constants';
import * as actionCreators from '@/store/actions';

export function useDeviceCommands() {
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);

  const { lastMessage, readyState } = useWebSocket(`${wsUrl}commands/${currentOrganizationId}/`, {
    protocols: ['base64.binary.k8s.io', AuthClass.getToken()],
    shouldReconnect: (closeEvent) => true,
    onClose: (event) => {
      if (event.code === 4003) {
        dispatch(actionCreators.logOut())
      }
    },
  });

  const dispatch = useDispatch();
  const { t } = useTranslation(['general', 'toasts']);

  const [latestCommands, setLatestCommands] = useState({});
  const [updateInProgressDevices, setUpdateInProgressDevices] = useState([]);
  const [checkInProgressDevices, setCheckInProgressDevices] = useState([]);

  const updateInProgress = (command) => {
    changeToast(
      dispatch,
      t('general:confirmation'),
      t('toasts:device_update_started', { count: updateInProgressDevices.length + 1 }),
      TOASTS_ICONS.loader,
      true,
      COMMANDS_TYPES.startUpdate
    );
    setUpdateInProgressDevices((prev) => {
      if (prev.includes(command.device_id)) {
        return prev;
      }
      return [...prev, command.device_id];
    });
  };

  const updateCompleted = (command) => {
    if (!updateInProgressDevices.includes(command.device_id)) {
      return;
    }
    setUpdateInProgressDevices((prev) => prev.filter((id) => id !== command.device_id));
    showToast(
      dispatch,
      t('general:confirmation'),
      <Trans i18nKey="toasts:device_updated">
        The Firmware update has been successfully installed for device
        {{ device: command.device_name }}
      </Trans>,
      false,
      true
    );
  };

  const updateError = (command) => {
    setUpdateInProgressDevices((prev) => prev.filter((id) => id !== command.device_id));
    const now = Math.floor(Date.now() / 1000)
    if (command.ts < now - 60 * 60) {
      return;
    }
    changeToast(
      dispatch,
      t('general:error'),
      <Trans i18nKey="toasts:device_update_failed">
        Firmware update error for device
        {{ device: command.device_name }}
      </Trans>,
      TOASTS_ICONS.warning,
      true,
      `error-${command.device_id}`
    );
  };

  const checkInProgress = (command) => {
    setCheckInProgressDevices((prev) => {
      if (prev.includes(command.device_id)) {
        return prev;
      }
      return [...prev, command.device_id];
    });
  };

  const checkCompleted = (command) => {
    if (!checkInProgressDevices.includes(command.device_id) || !command.message) {
      return;
    }
    changeToast(
      dispatch,
      t('general:confirmation'),
      t('toasts:device_update_check', { count: checkInProgressDevices.length }),
      false,
      true,
      COMMANDS_TYPES.checkUpdate
    );
    setCheckInProgressDevices((prev) => prev.filter((id) => id !== command.device_id));
  };

  const processCommands = (commands) => {
    for (const cmd in commands) {
      const command = commands[cmd];
      if (!command) {
        continue;
      }
      switch (command.cmd) {
        case COMMANDS_TYPES.startUpdate:
          switch (command.device_result) {
            case COMMANDS_STATUS.processing:
              updateInProgress(command);
              break;
            case COMMANDS_STATUS.completed:
              updateCompleted(command);
              break;
            case COMMANDS_STATUS.error:
              updateError(command);
              break;
            default:
              break;
          }
          break;
        case COMMANDS_TYPES.checkUpdate:
          switch (command.device_result) {
            case COMMANDS_STATUS.processing:
              checkInProgress(command);
              break;
            case COMMANDS_STATUS.completed:
              checkCompleted(command);
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (!lastMessage) {
      return;
    }

    let commands = [];

    try {
      commands = JSON.parse(lastMessage.data);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return;
    }

    if (!Array.isArray(commands)) {
      return;
    }

    // check if it is first message with full commands list
    if (Object.keys(latestCommands).length === 0) {
      const lastCommands = {};

      // form map-like object for each device of last commands of each type
      commands.forEach((command) => {
        if (!lastCommands[command.device_id]) {
          lastCommands[command.device_id] = {};
        }
        lastCommands[command.device_id][command.cmd] = command;
      });

      setLatestCommands(lastCommands);
      return;
    }

    // suppose it is new message with single command, or take latest command from array
    const command = commands[commands.length - 1];
    if (!command) {
      return;
    }
    setLatestCommands({
      [command.device_id]: {
        [command.cmd]: command,
      },
    });
  }, [lastMessage]);

  useEffect(() => {
    if (updateInProgressDevices.length === 0) {
      deleteToast(dispatch, COMMANDS_TYPES.startUpdate);
    }
  }, [updateInProgressDevices]);

  useEffect(() => {
    dispatch(storeLatestCommands(latestCommands));
    for (const deviceId in latestCommands) {
      const command = latestCommands[deviceId];
      processCommands(command);
    }
  }, [latestCommands]);

  return { readyState };
}
