import { forwardRef } from 'react';
import { useMergeRefs, FloatingFocusManager } from '@floating-ui/react';
import { usePopoverContext } from './contexts';

import styles from './BasePopover.module.css';

const BasePopoverContent = forwardRef(({ style, ...props }, propRef) => {
  const { context: floatingContext, ...context } = usePopoverContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!floatingContext.open) return null;

  return (
      <FloatingFocusManager context={floatingContext} modal={context.modal}>
        <div
          className={styles.content}
          ref={ref}
          style={{ ...context.floatingStyles, ...style }}
          {...context.getFloatingProps(props)}
        >
          {props.children}
        </div>
      </FloatingFocusManager>
  );
});

export default BasePopoverContent;
