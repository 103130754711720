import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CIcon from '@coreui/icons-react';
import {
  cilSitemap,
  cilPlus,
  cilUser,
  cilOptions,
  cilSun,
  cilMoon,
  cilAccountLogout,
} from '@coreui/icons';
import cn from 'classnames';

import {
  BasePopover,
  BasePopoverTrigger,
  BasePopoverContent,
  BasePopoverItem,
} from '@/shared/ui/popover';
import { PLAN_NAMES } from '@/shared/constants';
import * as actionCreators from '@/store/actions';
import { isOwner } from '@/util/permissions';
import axios from '@/util/axios-config';
import { AuthClass } from '@/shared/helpers';
import useAnalyticsEventTracker from '@/util/hooks/useAnalyticsEventTracker';
import { cutString } from '@/util/basic';

import styles from './HeaderDropdown.module.css';

const HeaderDropdown = ({ className }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation(['headerDropdown']);

  const dispatch = useDispatch();

  const darkMode = useSelector(state => state.view.darkMode);
  const organizations = useSelector(state => state.currentUser.organizations);
  const currentOrgId = useSelector(state => state.currentUser.currentOrganizationId);
  const ownId = useSelector(state => state.currentUser.ownId);
  const isVerified = useSelector(state => state.authorization.isVerified);
  const locations = useSelector(state => state.currentUser.locations);

  const [opened, setOpened] = useState(false);

  const trackGAEvent = useAnalyticsEventTracker('Top bar');

  const hasOrg = organizations.length > 0;

  function onToggleMode() {
    dispatch(actionCreators.setColorTheme(!darkMode));
    axios.patch(
      'auth/users/me/',
      { mode: !darkMode ? 'dark' : 'light' },
      { headers: AuthClass.authHeaders() }
    );
  }

  function onClickOrg(orgId) {
    dispatch(
      actionCreators.handleOrganiztionChange(
        orgId,
        pathname,
        organizations,
        locations,
        navigate
      )
    );

    setOpened(false);
  }

  let verifiedContent = null;
  let orgContent = null;
  if (isVerified) {
    orgContent = (
      <>
        <h6 className={styles.subtitle}>{t('headerDropdown:organizations')}</h6>
        {hasOrg &&
          organizations.map((org) => (
            <BasePopoverItem
              className={styles.organizationItem}
              key={org.id}
              onClick={() => onClickOrg(org.id)}
              isActive={org.id === currentOrgId}
              data-testid={`header-goto-org-${org.id}`}
            >
              <div className={styles.dropdownItemContent}>
                <CIcon icon={cilSitemap} />
                {cutString(org.name, org.plan.name !== PLAN_NAMES.free ? 15 : 20)}
              </div>
              {org.plan?.description?.name && org.plan.name !== PLAN_NAMES.free && (
                <span
                  className={cn(
                    styles.iconPro,
                    darkMode ? styles.iconProThemeWhite : styles.iconProThemeDark
                  )}
                >
                  {org.plan.description.name.toUpperCase()}
                </span>
              )}
            </BasePopoverItem>
          ))}
        {(!isOwner(organizations, ownId) || !hasOrg) && (
          <BasePopoverItem
            onClick={() => {
              trackGAEvent('top_create_organization');
              navigate('/create_workspace');
              setOpened(false);
            }}
            data-testid='header-create-org'
          >
            <div className={styles.dropdownItemContent}>
              <CIcon icon={cilPlus} />
              {t('headerDropdown:createOrg')}
            </div>
          </BasePopoverItem>
        )}
      </>
    );

    verifiedContent = (
      <>
        {orgContent}
        <h6 className={styles.subtitle}>{t('headerDropdown:account')}</h6>
        <BasePopoverItem
          onClick={() => {
            trackGAEvent('top_profile');
            navigate('/profile');
            setOpened(false);
          }}
          data-testid='header-profile'
        >
          <div className={styles.dropdownItemContent}>
            <CIcon icon={cilUser} />
            {t('headerDropdown:profile')}
          </div>
        </BasePopoverItem>
      </>
    );
  }

  return (
    <BasePopover
      className={className}
      placement="bottom-end"
      open={opened}
      onOpenChange={setOpened}
    >
      <BasePopoverTrigger asChild={true} onClick={() => setOpened((val) => !val)}>
        <CIcon className={styles.toggleIcon} size="lg" icon={cilOptions} data-testid="header-user-dropdown" />
      </BasePopoverTrigger>
      <BasePopoverContent>
        {verifiedContent}
        <BasePopoverItem
          onClick={() => {
            trackGAEvent('top_dark_light_mode');
            onToggleMode();
            setOpened(false);
          }}
          data-testid="header-toggle-dark-light-mode"
        >
          <div className={styles.dropdownItemContent}>
            <CIcon icon={darkMode ? cilSun : cilMoon} />
            {t('headerDropdown:toggleMode')}
          </div>
        </BasePopoverItem>
        <BasePopoverItem
          className={styles.dropdownItemSeparated}
          onClick={() => {
            trackGAEvent('top_logout');
            dispatch(actionCreators.logOut());
            dispatch(actionCreators.clearToasts());
            navigate('/');
            setOpened(false);
          }}
          data-testid='header-logout'
        >
          <div className={styles.dropdownItemContent}>
            <CIcon icon={cilAccountLogout} />
            {t('headerDropdown:logout')}
          </div>
        </BasePopoverItem>
      </BasePopoverContent>
    </BasePopover>
  );
};

export default HeaderDropdown;
