import { usePopover } from './hooks/use-popover';
import { PopoverContext } from './contexts';

const BasePopover = ({
  children,
  modal = false,
  ...restOptions
}) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions });

  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  );
}

export default BasePopover;
