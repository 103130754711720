import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../util/axios-config';
import { AuthClass } from '@/shared/helpers';

const initialState = {
  devices: [],
  fetchDevicesError: {},
  otaCheckResult: {},
  otaCheckError: {},
  coloursByDeviceIds: new Map(),
};

export const fetchAllDevices = createAsyncThunk(
  'devices/fetchAllDevices',
  async ({ currentOrganizationId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`organizations/${currentOrganizationId}/devices/`, {
        headers: AuthClass.authHeaders(),
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const fetchDeviceById = createAsyncThunk(
  'devices/fetchDeviceById',
  async ({ currentOrganizationId, deviceId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `organizations/${currentOrganizationId}/devices/${deviceId}/`,
        {
          headers: AuthClass.authHeaders(),
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const fetchAllDevicesOtaCheck = createAsyncThunk(
  'devices/fetchAllDevicesOtaCheck',
  async ({ currentOrganizationId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/organizations/${currentOrganizationId}/devices/ota_check_all/`,
        {},
        { headers: AuthClass.authHeaders() }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const fetchNoticedDevicesOtaCheck = createAsyncThunk(
  'devices/fetchNoticedDevicesOtaCheck',
  async ({ currentOrganizationId, selectedRows }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/organizations/${currentOrganizationId}/devices/ota_check/`,
        { devices: selectedRows },
        { headers: AuthClass.authHeaders() }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setColoursByDeviceIds: (state, action) => {
      state.coloursByDeviceIds = action.payload;
    },
  },
  extraReducers: {
    [fetchAllDevices.fulfilled]: (state, action) => {
      state.devices = action.payload;
    },
    [fetchAllDevices.rejected]: (state, action) => {
      state.fetchDevicesError = action.payload;
    },
    [fetchAllDevicesOtaCheck.fulfilled]: (state, action) => {
      state.otaCheckResult = action.payload;
    },
    [fetchAllDevicesOtaCheck.rejected]: (state, action) => {
      state.otaCheckError = action.payload;
    },
  },
});

export const { setColoursByDeviceIds } = devicesSlice.actions;
export default devicesSlice.reducer;
