import { createContext, useContext } from 'react';

export const PopoverContext = createContext(null);

export function usePopoverContext() {
  const context = useContext(PopoverContext);

  if (context == null) {
    throw new Error("Popover components must be wrapped in <Popover />");
  }

  return context;
};
