export const NOTIFICATION_CATEGORIES = {
  billing: 'billing',
  system: 'system',
  alert: 'alert',
}

export const NOTIFICATIONS_INTERVALS = [
  {
    id: 15,
  },
  {
    id: 30,
  },
  {
    id: 60,
  },
  {
    id: 720,
  },
  {
    id: 1440,
  },
];
